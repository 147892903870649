import React from 'react';
import { Building, Users } from 'lucide-react';
import { formatNumber } from '../../../utils/format/currency';

interface MarketRateProps {
  type: 'official' | 'parallel';
  rate: number;
  currency: string;
}

export function MarketRate({ type, rate, currency }: MarketRateProps) {
  const Icon = type === 'official' ? Building : Users;
  const title = type === 'official' ? 'Official Rate' : 'Parallel Rate';
  const subtitle = type === 'official' ? 'CBN Rate' : 'Market Rate';
  const iconColor = type === 'official' ? 'text-blue-600' : 'text-purple-600';

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <div className="flex items-center gap-2 mb-2">
        <Icon className={`h-4 w-4 ${iconColor}`} />
        <span className="font-medium">{title}</span>
      </div>
      <p className="text-2xl font-bold">
        {formatNumber(rate)} {currency}
      </p>
      <p className="text-sm text-gray-600">{subtitle}</p>
    </div>
  );
}