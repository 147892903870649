import React from 'react';
import { Activity, TrendingUp, TrendingDown, RefreshCw } from 'lucide-react';
import { useMarketData } from '../../hooks/useMarketData';
import { LoadingSpinner } from '../shared/LoadingSpinner';
import { ErrorAlert } from '../shared/ErrorAlert';

export function MarketOverview() {
  const { data: marketData, isLoading, isError, refetch } = useMarketData();

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <ErrorAlert 
          message="Failed to load market data" 
          onRetry={refetch} 
        />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Activity className="h-5 w-5 text-emerald-600" />
          <h3 className="text-lg font-semibold">Market Overview</h3>
        </div>
        <button
          onClick={() => refetch()}
          className="flex items-center gap-2 text-sm text-emerald-600 hover:text-emerald-700"
        >
          <RefreshCw className="h-4 w-4" />
          Refresh
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {marketData?.map((item) => (
          <div key={item.symbol} className="p-4 border rounded-lg bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <span className="font-medium">{item.symbol}</span>
              <span className={`flex items-center ${
                item.change >= 0 ? 'text-green-600' : 'text-red-600'
              }`}>
                {item.change >= 0 ? (
                  <TrendingUp className="h-4 w-4 mr-1" />
                ) : (
                  <TrendingDown className="h-4 w-4 mr-1" />
                )}
                {item.formattedChange}
              </span>
            </div>
            <p className="text-2xl font-bold">{item.formattedPrice}</p>
            <p className="text-sm text-gray-500">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}