export const CURRENCIES = {
  NGN: { code: 'NGN', name: 'Nigerian Naira', symbol: '₦' },
  USD: { code: 'USD', name: 'US Dollar', symbol: '$' },
  EUR: { code: 'EUR', name: 'Euro', symbol: '€' },
  GBP: { code: 'GBP', name: 'British Pound', symbol: '£' },
  CAD: { code: 'CAD', name: 'Canadian Dollar', symbol: 'C$' },
  AUD: { code: 'AUD', name: 'Australian Dollar', symbol: 'A$' },
  XOF: { code: 'XOF', name: 'CFA Franc', symbol: 'CFA' },
} as const;

// Currencies available in the converter
export const AVAILABLE_CURRENCIES = [
  { code: 'NGN', name: 'Nigerian Naira', symbol: '₦' },
  { code: 'USD', name: 'US Dollar', symbol: '$' },
  { code: 'EUR', name: 'Euro', symbol: '€' },
  { code: 'GBP', name: 'British Pound', symbol: '£' },
  { code: 'CAD', name: 'Canadian Dollar', symbol: 'C$' },
  { code: 'AUD', name: 'Australian Dollar', symbol: 'A$' },
  { code: 'XOF', name: 'CFA Franc', symbol: 'CFA' },
];

// Order of currencies in the live rates display
export const MAIN_CURRENCIES = ['USD', 'EUR', 'GBP', 'AUD', 'XOF', 'CAD'];