import React from 'react';
import { TrendingUp } from 'lucide-react';
import { MarketRate } from './MarketRate';
import { RateDifference } from './RateDifference';

interface RateComparisonProps {
  fromCurrency: string;
  toCurrency: string;
  officialRate: number;
  parallelRate: number;
}

export function RateComparison({
  fromCurrency,
  toCurrency,
  officialRate,
  parallelRate,
}: RateComparisonProps) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-6">
        <TrendingUp className="h-5 w-5 text-emerald-600" />
        <h2 className="text-xl font-semibold">Market Comparison</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <MarketRate 
          type="official" 
          rate={officialRate} 
          currency={toCurrency} 
        />
        <MarketRate 
          type="parallel" 
          rate={parallelRate} 
          currency={toCurrency} 
        />
      </div>

      <RateDifference 
        officialRate={officialRate} 
        parallelRate={parallelRate} 
      />
    </div>
  );
}