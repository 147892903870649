import { ExchangeRate } from './types';
import { AVAILABLE_CURRENCIES } from './constants';

interface RateHistory {
  rates: number[];
  lastUpdate: number;
}

// Store rate history with automatic cleanup
class RateHistoryManager {
  private history: Map<string, RateHistory> = new Map();
  private readonly maxHistory = 10;
  private readonly historyTTL = 5 * 60 * 1000; // 5 minutes

  addRate(currency: string, rate: number): void {
    const now = Date.now();
    const current = this.history.get(currency);

    if (current && now - current.lastUpdate > this.historyTTL) {
      // Reset history if too old
      this.history.delete(currency);
    }

    const history = this.history.get(currency) || { rates: [], lastUpdate: now };
    history.rates.push(rate);
    history.lastUpdate = now;

    if (history.rates.length > this.maxHistory) {
      history.rates.shift();
    }

    this.history.set(currency, history);
  }

  getLastRate(currency: string): number | null {
    const history = this.history.get(currency);
    if (!history) return null;

    const rates = history.rates;
    return rates[rates.length - 2] || null;
  }

  cleanup(): void {
    const now = Date.now();
    for (const [currency, history] of this.history.entries()) {
      if (now - history.lastUpdate > this.historyTTL) {
        this.history.delete(currency);
      }
    }
  }
}

const rateHistory = new RateHistoryManager();

// Clean up old history entries periodically
setInterval(() => rateHistory.cleanup(), 60000);

export function transformRateData(code: string, currentRate: number): ExchangeRate {
  const currency = AVAILABLE_CURRENCIES.find(c => c.code === code);
  if (!currency) {
    throw new Error(`Invalid currency code: ${code}`);
  }

  // Add current rate to history
  rateHistory.addRate(code, currentRate);

  // Calculate percentage change
  const previousRate = rateHistory.getLastRate(code) || currentRate;
  const change = ((currentRate - previousRate) / previousRate) * 100;

  return {
    fromCurrency: code,
    toCurrency: 'NGN',
    rate: Number(currentRate.toFixed(4)),
    change: Number(change.toFixed(2)),
    timestamp: new Date(),
    symbol: currency.symbol
  };
}