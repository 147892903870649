import { registerSW } from 'virtual:pwa-register';

export function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    const updateSW = registerSW({
      onNeedRefresh() {
        const event = new CustomEvent('pwa:update-available');
        window.dispatchEvent(event);
      },
      onOfflineReady() {
        const event = new CustomEvent('pwa:offline-ready');
        window.dispatchEvent(event);
      },
      onRegistered(registration) {
        if (registration) {
          setInterval(() => {
            registration.update();
          }, 60 * 60 * 1000); // Check for updates every hour
        }
      },
      immediate: true
    });

    return updateSW;
  }
}