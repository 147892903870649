export interface SafetyTip {
  title: string;
  description: string;
}

export const safetyTips: SafetyTip[] = [
  {
    title: "Use Licensed Operators",
    description: "Only exchange money through CBN-licensed operators or established banks.",
  },
  {
    title: "Verify Rates",
    description: "Cross-check exchange rates with multiple sources before any transaction.",
  },
  {
    title: "Document Everything",
    description: "Keep receipts and documentation of all forex transactions.",
  },
  {
    title: "Avoid Street Trading",
    description: "Never exchange currency with unauthorized street traders.",
  },
  {
    title: "Be Cautious Online",
    description: "Verify the legitimacy of online forex platforms before trading.",
  }
];