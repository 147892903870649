import React from 'react';
import { Download, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { usePWAInstall } from '../../../hooks/usePWAInstall';

export function InstallPrompt() {
  const { isInstallable, handleInstall } = usePWAInstall();
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (isInstallable) {
        setIsVisible(true);
      }
    }, 3000); // Show prompt after 3 seconds

    return () => clearTimeout(timer);
  }, [isInstallable]);

  if (!isVisible) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className="fixed bottom-4 left-4 right-4 md:left-auto md:right-4 md:w-96 bg-white rounded-lg shadow-xl p-4 border border-gray-200 z-50"
      >
        <button
          onClick={() => setIsVisible(false)}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        >
          <X className="h-5 w-5" />
        </button>
        
        <div className="flex items-start mb-4">
          <div className="bg-emerald-100 rounded-lg p-2 mr-4">
            <Download className="h-6 w-6 text-emerald-600" />
          </div>
          <div>
            <h3 className="font-semibold text-gray-900">Install NigeriaFx</h3>
            <p className="text-sm text-gray-600">
              Add our app to your home screen for quick access to live rates
            </p>
          </div>
        </div>
        
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setIsVisible(false)}
            className="text-sm text-gray-600 hover:text-gray-900"
          >
            Maybe later
          </button>
          <button
            onClick={() => {
              handleInstall();
              setIsVisible(false);
            }}
            className="px-4 py-2 bg-emerald-600 text-white rounded-lg text-sm font-medium hover:bg-emerald-700"
          >
            Install Now
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}