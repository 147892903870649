import React from 'react';
import { Bell, Plus } from 'lucide-react';

interface Alert {
  id: string;
  currency: string;
  threshold: number;
  type: 'above' | 'below';
  active: boolean;
}

export function RateAlerts() {
  const [alerts, setAlerts] = React.useState<Alert[]>([]);
  const [showForm, setShowForm] = React.useState(false);
  const [newAlert, setNewAlert] = React.useState({
    currency: 'USD',
    threshold: 0,
    type: 'above' as const,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAlerts((prev) => [
      ...prev,
      {
        id: Date.now().toString(),
        ...newAlert,
        active: true,
      },
    ]);
    setShowForm(false);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <Bell className="h-5 w-5 text-emerald-600 mr-2" />
          <h3 className="text-lg font-semibold">Rate Alerts</h3>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center text-sm text-emerald-600 hover:text-emerald-700"
        >
          <Plus className="h-4 w-4 mr-1" />
          Add Alert
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="mb-6 p-4 border rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Currency
              </label>
              <select
                value={newAlert.currency}
                onChange={(e) => setNewAlert({ ...newAlert, currency: e.target.value })}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type
              </label>
              <select
                value={newAlert.type}
                onChange={(e) => setNewAlert({ ...newAlert, type: e.target.value as 'above' | 'below' })}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              >
                <option value="above">Above</option>
                <option value="below">Below</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Threshold
              </label>
              <input
                type="number"
                value={newAlert.threshold}
                onChange={(e) => setNewAlert({ ...newAlert, threshold: parseFloat(e.target.value) })}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="px-4 py-2 text-sm text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm text-white bg-emerald-600 rounded-md hover:bg-emerald-700"
            >
              Create Alert
            </button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {alerts.map((alert) => (
          <div
            key={alert.id}
            className="flex items-center justify-between p-4 border rounded-lg"
          >
            <div>
              <p className="font-medium">
                {alert.currency}/NGN {alert.type} ₦{alert.threshold}
              </p>
              <p className="text-sm text-gray-500">
                Notify when rate goes {alert.type} ₦{alert.threshold}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setAlerts(alerts.filter((a) => a.id !== alert.id))}
                className="text-red-600 hover:text-red-700 text-sm"
              >
                Delete
              </button>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={alert.active}
                  onChange={() => {
                    setAlerts(alerts.map((a) =>
                      a.id === alert.id ? { ...a, active: !a.active } : a
                    ));
                  }}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-emerald-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-emerald-600"></div>
              </label>
            </div>
          </div>
        ))}
        {alerts.length === 0 && !showForm && (
          <p className="text-center text-gray-500 py-4">
            No alerts set. Click "Add Alert" to create one.
          </p>
        )}
      </div>
    </div>
  );
}