import React from 'react';
import { ArrowUpRight } from 'lucide-react';
import { formatCurrency } from '../../../utils/format/currency';

interface HistoricalRateProps {
  pair: string;
  oldRate: number;
  currentRate: number;
  percentageChange: number;
  year: number;
}

export function HistoricalRate({ 
  pair, 
  oldRate, 
  currentRate, 
  percentageChange,
  year 
}: HistoricalRateProps) {
  return (
    <div className="bg-gray-50 p-6 rounded-lg hover:bg-gray-100 transition-colors">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold">{pair}</h3>
        <div className="flex items-center text-emerald-600">
          <ArrowUpRight className="h-4 w-4 mr-1" />
          <span className="font-medium">{percentageChange.toFixed(1)}%</span>
        </div>
      </div>
      
      <div className="space-y-4">
        <div>
          <p className="text-sm text-gray-500 mb-1">{year} Rate</p>
          <p className="text-2xl font-bold">{formatCurrency(oldRate)}</p>
        </div>
        
        <div>
          <p className="text-sm text-gray-500 mb-1">Current Rate</p>
          <p className="text-2xl font-bold">{formatCurrency(currentRate)}</p>
        </div>
      </div>
    </div>
  );
}