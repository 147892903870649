import React from 'react';
import { Download, Wifi, Zap, Bell } from 'lucide-react';
import { motion } from 'framer-motion';
import { usePWAInstall } from '../../hooks/usePWAInstall';

const features = [
  {
    icon: Download,
    title: 'Install on Any Device',
    description: 'Add NigeriaFx to your home screen for quick access to live rates.'
  },
  {
    icon: Wifi,
    title: 'Works Offline',
    description: 'Access exchange rates even without an internet connection.'
  },
  {
    icon: Zap,
    title: 'Fast & Responsive',
    description: 'Lightning-fast performance with real-time updates.'
  },
  {
    icon: Bell,
    title: 'Stay Updated',
    description: 'Get notifications for important rate changes and updates.'
  }
];

export function PWASection() {
  const { isInstallable, isInstalled, handleInstall } = usePWAInstall();

  return (
    <section className="py-8 bg-emerald-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-1 font-heading">
              Download Our App
            </h2>
            <p className="text-emerald-100">
              Get the best exchange rate experience with our Progressive Web App
            </p>
          </motion.div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-emerald-800/50 backdrop-blur rounded-lg p-4 text-center hover:bg-emerald-800 transition-colors"
            >
              <div className="inline-flex items-center justify-center w-10 h-10 bg-emerald-600 rounded-lg mb-2">
                <feature.icon className="h-5 w-5 text-white" />
              </div>
              <h3 className="text-base font-semibold mb-1">{feature.title}</h3>
              <p className="text-emerald-100 text-sm">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-center mb-4"
        >
          {isInstalled ? (
            <div className="bg-emerald-800/50 backdrop-blur rounded-lg p-3 inline-block">
              <p className="text-emerald-100 text-sm">
                App installed successfully! Open it from your home screen.
              </p>
            </div>
          ) : isInstallable ? (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleInstall}
              className="inline-flex items-center px-6 py-3 bg-white text-emerald-900 rounded-lg font-semibold hover:bg-emerald-50 transition-colors shadow-lg text-base group"
            >
              <Download className="h-5 w-5 mr-2 group-hover:animate-bounce" />
              Install NigeriaFx App
            </motion.button>
          ) : (
            <div className="bg-emerald-800/50 backdrop-blur rounded-lg p-3 inline-block">
              <p className="text-emerald-100 text-sm">
                Open this site in Chrome, Edge, or Safari to install the app
              </p>
            </div>
          )}
        </motion.div>
      </div>
    </section>
  );
}