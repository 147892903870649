import React from 'react';
import { Shield, CheckCircle } from 'lucide-react';
import { SectionHeader } from '../ui/SectionHeader';
import { safetyTips } from '../../data/education/tips';

export function SafetyTips() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <SectionHeader icon={Shield} title="Safety Tips" />

      <div className="grid gap-4">
        {safetyTips.map((tip, index) => (
          <div key={index} className="flex items-start gap-3 p-4 bg-gray-50 rounded-lg">
            <CheckCircle className="h-5 w-5 text-emerald-600 mt-0.5" />
            <div>
              <h3 className="font-semibold mb-1">{tip.title}</h3>
              <p className="text-gray-600">{tip.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}