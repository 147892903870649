import React from 'react';
import { formatPercentage } from '../../../utils/format/currency';

interface RateDifferenceProps {
  officialRate: number;
  parallelRate: number;
}

export function RateDifference({ officialRate, parallelRate }: RateDifferenceProps) {
  const difference = parallelRate - officialRate;
  const percentageDiff = (difference / officialRate) * 100;

  return (
    <div className="mt-4 p-3 bg-gray-50 rounded-lg">
      <p className="text-sm text-gray-600">
        The parallel market rate is{' '}
        <span className="font-medium text-emerald-600">
          {formatPercentage(percentageDiff)}
        </span>{' '}
        compared to the official rate
      </p>
    </div>
  );
}