import React from 'react';
import { Menu, X } from 'lucide-react';
import { Logo } from '../shared/Logo';
import { NavLink } from './Navigation/NavLink';
import { MAIN_NAV_ITEMS } from '../../constants/navigation';
import { InstallButton } from '../shared/pwa/InstallButton';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <header className="bg-emerald-700 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-28">
          <Logo />
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <nav className="flex space-x-8">
              {MAIN_NAV_ITEMS.map(({ to, label }) => (
                <NavLink key={to} to={to} className="text-lg py-8">
                  {label}
                </NavLink>
              ))}
            </nav>
            <InstallButton />
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <nav className="md:hidden px-4 pt-2 pb-4 space-y-2">
          {MAIN_NAV_ITEMS.map(({ to, label }) => (
            <NavLink
              key={to}
              to={to}
              className="block hover:bg-emerald-600 px-3 py-3 rounded-md text-base"
              onClick={() => setIsMenuOpen(false)}
            >
              {label}
            </NavLink>
          ))}
          <div className="px-3 py-3">
            <InstallButton />
          </div>
        </nav>
      )}
    </header>
  );
}