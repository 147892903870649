export const RATE_CONFIG = {
  API_BASE_URL: 'https://api.exchangerate.host',
  TIMEOUT: 10000,
  RETRY_ATTEMPTS: 3,
  CACHE_TIME: 1000 * 60 * 5, // 5 minutes
  CACHE_TTL: 10000, // 10 seconds
  REFETCH_INTERVAL: 10000, // 10 seconds
  STALE_TIME: 5000, // 5 seconds
  BATCH_SIZE: 10,
  REFRESH_DEBOUNCE: 500, // 500ms
  ANIMATION_DURATION: 300, // 300ms
} as const;

export const ERROR_MESSAGES = {
  FETCH_FAILED: 'Failed to fetch exchange rates',
  INVALID_RESPONSE: 'Invalid response from rate service',
  RATE_NOT_FOUND: (currency: string) => `Exchange rate not found for ${currency}`,
  SERVICE_UNAVAILABLE: 'Rate service is temporarily unavailable',
  NETWORK_ERROR: 'Please check your internet connection',
} as const;