import React from 'react';
import { Globe, BarChart3, Clock } from 'lucide-react';
import { motion } from 'framer-motion';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

export function Stats() {
  return (
    <motion.div 
      variants={container}
      initial="hidden"
      animate="show"
      className="relative -mt-16 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <motion.div 
          variants={item}
          className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-transform"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 bg-emerald-100 rounded-lg">
              <Globe className="h-6 w-6 text-emerald-600" />
            </div>
            <div>
              <div className="text-2xl font-bold">6 Pairs</div>
              <div className="text-gray-600">Major Currencies</div>
            </div>
          </div>
        </motion.div>

        <motion.div 
          variants={item}
          className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-transform"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 bg-emerald-100 rounded-lg">
              <BarChart3 className="h-6 w-6 text-emerald-600" />
            </div>
            <div>
              <div className="text-2xl font-bold">30s Updates</div>
              <div className="text-gray-600">Live Exchange Rates</div>
            </div>
          </div>
        </motion.div>

        <motion.div 
          variants={item}
          className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-transform"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 bg-emerald-100 rounded-lg">
              <Clock className="h-6 w-6 text-emerald-600" />
            </div>
            <div>
              <div className="text-2xl font-bold">25 Years</div>
              <div className="text-gray-600">Historical Data</div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}