import React from 'react';
import { PageBanner } from '../components/shared/PageBanner';
import { AboutDisclaimer } from '../components/about/Disclaimer';
import { AboutPurpose } from '../components/about/Purpose';
import { AboutLimitations } from '../components/about/Limitations';

export function About() {
  return (
    <div className="min-h-screen bg-gray-50">
      <PageBanner 
        title="About NigeriaFx"
        description="Important information about our services and limitations"
        backgroundImage="https://images.unsplash.com/photo-1639322537228-f710d846310a"
      />
      
      <div className="max-w-7xl mx-auto px-4 py-12 space-y-16">
        <AboutPurpose />
        <AboutLimitations />
        <AboutDisclaimer />
      </div>
    </div>
  );
}