import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../../utils/cn';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export function NavLink({ to, children, className, onClick }: NavLinkProps) {
  return (
    <Link
      to={to}
      className={cn(
        "hover:text-emerald-200 transition-colors",
        className
      )}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}