import React from 'react';
import { Link } from 'react-router-dom';

export function CTASection() {
  return (
    <section className="bg-emerald-900 text-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold mb-4 font-heading">Start Trading Smarter Today</h2>
        <p className="text-lg text-emerald-100 mb-8 max-w-2xl mx-auto">
          Join thousands of traders who rely on NigeriaFx for accurate rates and market insights
        </p>
        <div className="flex flex-wrap gap-4 justify-center">
          <Link
            to="/converter"
            className="bg-white text-emerald-900 px-8 py-4 rounded-lg font-semibold hover:bg-emerald-50 transition-colors shadow-lg"
          >
            Get Started Now
          </Link>
          <Link
            to="/education"
            className="bg-emerald-800 text-white px-8 py-4 rounded-lg font-semibold hover:bg-emerald-700 transition-colors"
          >
            Explore Resources
          </Link>
        </div>
      </div>
    </section>
  );
}