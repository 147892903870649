import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Wallet } from 'lucide-react';
import { Button } from '../ui/Button';

export function QuickActions() {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-4">
        <Wallet className="h-5 w-5 text-emerald-600" />
        <h2 className="text-lg font-semibold">Quick Actions</h2>
      </div>
      <div className="space-y-4">
        <Button className="w-full" onClick={() => navigate('/converter')}>
          Convert Currency
        </Button>
        <Button
          variant="outline"
          className="w-full"
          onClick={() => navigate('/education')}
        >
          View Trading Guides
        </Button>
      </div>
    </div>
  );
}