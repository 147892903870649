import React from 'react';
import { TrendingUp, TrendingDown, RefreshCw } from 'lucide-react';
import { useExchangeRates } from '../../hooks/useExchangeRates';
import { LoadingSpinner } from '../shared/LoadingSpinner';
import { ErrorAlert } from '../shared/ErrorAlert';
import { formatDistanceToNow } from 'date-fns';
import { formatCurrency, formatPercentage } from '../../utils/format/currency';

export function LiveRates() {
  const { data: rates, isLoading, isError, refetch } = useExchangeRates();

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="bg-white rounded-lg shadow-md p-6">
            <LoadingSpinner />
          </div>
        ))}
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <ErrorAlert 
          message="Failed to load exchange rates" 
          onRetry={refetch}
        />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {rates?.map((rate) => (
        <div
          key={rate.fromCurrency}
          className="bg-white rounded-lg shadow-md p-6"
        >
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="text-lg font-semibold">
                {rate.fromCurrency}/NGN
              </h3>
              <span className="text-sm text-gray-500">
                Market Rate
              </span>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => refetch()}
                className="p-1 text-gray-400 hover:text-emerald-600 rounded-full hover:bg-gray-100"
                title="Refresh rates"
              >
                <RefreshCw className="h-4 w-4" />
              </button>
              <span className="text-sm text-gray-500">
                {formatDistanceToNow(rate.timestamp, { addSuffix: true })}
              </span>
            </div>
          </div>

          <div className="text-center">
            <p className="text-3xl font-bold mb-2">
              {formatCurrency(rate.rate, 'NGN')}
            </p>
            <div className={`flex items-center justify-center ${
              rate.change >= 0 ? 'text-green-600' : 'text-red-600'
            }`}>
              {rate.change >= 0 ? (
                <TrendingUp className="h-4 w-4 mr-1" />
              ) : (
                <TrendingDown className="h-4 w-4 mr-1" />
              )}
              <span className="font-medium">
                {formatPercentage(rate.change)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}