import React from 'react';
import { PageBanner } from '../components/shared/PageBanner';
import { ConversionForm } from '../components/converter/ConversionForm';
import { ConversionHistory } from '../components/converter/ConversionHistory';
import { RateComparison } from '../components/converter/RateComparison';
import type { ConversionHistory as ConversionHistoryType } from '../types/converter';
import { useExchangeRates } from '../hooks/useExchangeRates';
import { AVAILABLE_CURRENCIES } from '../types/converter';

export function Converter() {
  const [fromAmount, setFromAmount] = React.useState(1);
  const [fromCurrency, setFromCurrency] = React.useState('USD');
  const [toCurrency, setToCurrency] = React.useState('NGN');
  const [history, setHistory] = React.useState<ConversionHistoryType[]>([]);
  const { data: rates } = useExchangeRates();

  const currentRate = React.useMemo(() => {
    const rate = rates?.find(r => r.fromCurrency === fromCurrency);
    return rate?.rate || 0;
  }, [rates, fromCurrency]);

  const toAmount = fromAmount * currentRate;

  const handleSwapCurrencies = () => {
    if (toCurrency === 'NGN') {
      setFromCurrency(toCurrency);
      setToCurrency(fromCurrency);
    }
  };

  const handleConvert = () => {
    const newConversion: ConversionHistoryType = {
      id: Date.now().toString(),
      result: {
        fromAmount,
        toAmount,
        rate: currentRate,
        fromCurrency,
        toCurrency,
        timestamp: new Date(),
      },
    };
    setHistory((prev) => [newConversion, ...prev.slice(0, 9)]);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <PageBanner 
        title="Currency Converter"
        description="Convert between Nigerian Naira and major world currencies with real-time exchange rates"
      />

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Conversion Form */}
          <div className="lg:col-span-1">
            <ConversionForm
              fromAmount={fromAmount}
              fromCurrency={fromCurrency}
              toCurrency={toCurrency}
              toAmount={toAmount}
              rate={currentRate}
              onFromAmountChange={setFromAmount}
              onFromCurrencyChange={setFromCurrency}
              onToCurrencyChange={setToCurrency}
              onSwapCurrencies={handleSwapCurrencies}
              onConvert={handleConvert}
            />
          </div>

          {/* Rate Comparison and History */}
          <div className="lg:col-span-2 space-y-8">
            <RateComparison
              fromCurrency={fromCurrency}
              toCurrency={toCurrency}
              officialRate={currentRate * 0.95} // Simulated official rate
              parallelRate={currentRate}
            />
            <ConversionHistory history={history} />
          </div>
        </div>
      </div>
    </div>
  );
}