import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../services/firebase';
import { AuthHeader } from '../auth/AuthHeader';
import { QuickActions } from './QuickActions';
import { RateAlerts } from '../dashboard/RateAlerts';
import { LiveRatesSection } from '../dashboard/LiveRatesSection';
import { MarketOverview } from '../dashboard/MarketOverview';

export function UserDashboard() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <AuthHeader onLogout={handleLogout} />

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-8">
            <LiveRatesSection />
            <MarketOverview />
          </div>
          <div className="space-y-8">
            <QuickActions />
            <RateAlerts />
          </div>
        </div>
      </div>
    </div>
  );
}