import React from 'react';
import { Mail } from 'lucide-react';
import { Logo } from '../shared/Logo';
import { NavLink } from '../navigation/NavLink';
import { LEGAL_NAV_ITEMS } from '../../constants/navigation';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pb-8">
          {/* Brand Section */}
          <div className="text-center md:text-left">
            <Logo />
          </div>

          {/* Legal Links */}
          <div className="text-center">
            <h3 className="text-white text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              {LEGAL_NAV_ITEMS.map(({ to, label }) => (
                <li key={to}>
                  <NavLink to={to} className="text-gray-300">
                    {label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Section */}
          <div className="text-center md:text-right">
            <h3 className="text-white text-lg font-semibold mb-4">Contact Us</h3>
            <div className="flex items-center justify-center md:justify-end gap-2 text-gray-300">
              <Mail className="h-5 w-5" />
              <a 
                href="mailto:contact@nigeriafx.com" 
                className="hover:text-emerald-500 transition-colors"
              >
                contact@nigeriafx.com
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8">
          <div className="text-gray-400 text-center">
            <p className="text-sm">© {currentYear} NigeriaFx. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}