import React from 'react';
import { Clock } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { formatCurrency } from '../../utils/format/currency';
import type { ConversionHistory } from '../../types/converter';

interface ConversionHistoryProps {
  history: ConversionHistory[];
}

export function ConversionHistory({ history }: ConversionHistoryProps) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-6">Recent Conversions</h2>
      
      <div className="space-y-4">
        {history.map((item) => (
          <div
            key={item.id}
            className="p-4 bg-gray-50 rounded-lg space-y-2"
          >
            <div className="flex justify-between items-start">
              <div>
                <p className="font-medium">
                  {formatCurrency(item.result.fromAmount, item.result.fromCurrency)}
                  {' → '}
                  {formatCurrency(item.result.toAmount, item.result.toCurrency)}
                </p>
                <p className="text-sm text-gray-600">
                  Rate: {formatCurrency(item.result.rate, item.result.toCurrency)}
                </p>
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <Clock className="h-4 w-4 mr-1" />
                {formatDistanceToNow(item.result.timestamp, { addSuffix: true })}
              </div>
            </div>
          </div>
        ))}
        {history.length === 0 && (
          <p className="text-center text-gray-500 py-4">
            No conversion history yet
          </p>
        )}
      </div>
    </div>
  );
}