import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../../utils/cn';

interface LogoProps {
  className?: string;
  textColor?: string;
}

export function Logo({ 
  className = '', 
  textColor = 'text-white'
}: LogoProps) {
  return (
    <Link to="/" className={cn('inline-flex flex-col', className)}>
      <div className="flex items-center">
        <span className="text-4xl font-bold text-white ml-4">₦</span>
        <span className={`text-3xl font-bold ${textColor} ml-0.5`}>igeria</span>
        <span className={`text-2xl font-bold ${textColor}`}>Fx</span>
      </div>
      <span className="text-sm text-emerald-100 ml-4">
        Forex Rates, Financial News, and Market Analysis
      </span>
    </Link>
  );
}