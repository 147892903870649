import React from 'react';
import { ArrowRight, TrendingUp } from 'lucide-react';
import { Link } from 'react-router-dom';
import { LiveRates } from '../converter/LiveRates';

export function LiveRatesSection() {
  return (
    <section>
      <div className="flex items-center justify-between mb-4">
        <div>
          <div className="flex items-center gap-2">
            <TrendingUp className="h-5 w-5 text-emerald-600" />
            <h2 className="text-xl font-bold">Live Exchange Rates</h2>
          </div>
          <p className="text-sm text-gray-600 mt-1">
            Track major currency pairs against the Naira in real-time
          </p>
        </div>
        <Link
          to="/converter"
          className="text-emerald-600 hover:text-emerald-700 flex items-center font-medium"
        >
          View All Rates <ArrowRight className="ml-1 h-5 w-5" />
        </Link>
      </div>
      <LiveRates />
    </section>
  );
}