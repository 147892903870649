import React from 'react';
import { RefreshCw, X } from 'lucide-react';
import { motion } from 'framer-motion';

interface UpdatePromptProps {
  onUpdate: () => void;
  onDismiss: () => void;
}

export function UpdatePrompt({ onUpdate, onDismiss }: UpdatePromptProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed bottom-4 left-4 right-4 md:left-auto md:w-96 bg-white rounded-lg shadow-xl p-4 border border-gray-200"
    >
      <button
        onClick={onDismiss}
        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
      >
        <X className="h-5 w-5" />
      </button>
      
      <div className="flex items-start mb-4">
        <div className="bg-blue-100 rounded-lg p-2 mr-4">
          <RefreshCw className="h-6 w-6 text-blue-600" />
        </div>
        <div>
          <h3 className="font-semibold text-gray-900">Update Available</h3>
          <p className="text-sm text-gray-600">
            A new version of NigeriaFx is available. Update now for the latest features.
          </p>
        </div>
      </div>
      
      <div className="flex justify-end space-x-3">
        <button
          onClick={onDismiss}
          className="text-sm text-gray-600 hover:text-gray-900"
        >
          Later
        </button>
        <button
          onClick={onUpdate}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700"
        >
          Update Now
        </button>
      </div>
    </motion.div>
  );
}