import React from 'react';
import { Download } from 'lucide-react';
import { usePWAInstall } from '../../../hooks/usePWAInstall';
import { motion } from 'framer-motion';

export function InstallButton() {
  const { isInstallable, isInstalled, handleInstall } = usePWAInstall();

  if (isInstalled) {
    return (
      <div className="bg-emerald-100 text-emerald-800 px-4 py-2 rounded-lg inline-flex items-center">
        <span className="font-medium">App Installed</span>
      </div>
    );
  }

  if (!isInstallable) {
    return null;
  }

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleInstall}
      className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg 
                 hover:bg-emerald-700 transition-colors shadow-md"
    >
      <Download className="h-5 w-5 mr-2" />
      Install App
    </motion.button>
  );
}