import React from 'react';
import { LucideIcon } from 'lucide-react';

interface SectionHeaderProps {
  icon: LucideIcon;
  title: string;
  iconColor?: string;
}

export function SectionHeader({ icon: Icon, title, iconColor = "text-emerald-600" }: SectionHeaderProps) {
  return (
    <div className="flex items-center gap-2 mb-6">
      <Icon className={`h-6 w-6 ${iconColor}`} />
      <h2 className="text-2xl font-semibold">{title}</h2>
    </div>
  );
}