export interface FAQ {
  question: string;
  answer: string;
}

export const faqs: FAQ[] = [
  {
    question: "What's the difference between official and parallel market rates?",
    answer: "The official rate is set by the Central Bank of Nigeria (CBN), while the parallel market rate is determined by unofficial market forces of supply and demand.",
  },
  {
    question: "How can I verify if a forex dealer is legitimate?",
    answer: "Check the CBN's list of licensed forex dealers and bureaux de change. Legitimate operators should have visible CBN licenses and proper documentation.",
  },
  {
    question: "What documents do I need for forex transactions?",
    answer: "Typically, you'll need a valid ID, proof of address, and documentation showing the purpose of the transaction (like flight tickets for travel).",
  },
  {
    question: "Is it legal to buy forex from street traders?",
    answer: "No, it's not legal to buy forex from unauthorized street traders. Only conduct transactions through licensed operators.",
  },
  {
    question: "What are the daily transaction limits?",
    answer: "Limits vary by institution and transaction type. Banks typically have higher limits than bureaux de change. Check with your chosen operator for specific limits.",
  }
];