import { exchangeRateApi } from './api-client';
import { mockRates } from './mock-data';
import { MAIN_CURRENCIES } from './constants';
import { transformRateData } from './transformers';
import { rateCache } from './cache';
import { exchangeRateSchema } from './types';
import type { ExchangeRate } from './types';
import type { ExchangeRateResponse } from './api-client';

async function fetchLatestRates(): Promise<ExchangeRateResponse> {
  const cacheKey = 'latest-rates';
  const cachedData = rateCache.get(cacheKey);
  
  if (cachedData) {
    return cachedData;
  }

  const response = await exchangeRateApi.get<ExchangeRateResponse>('/latest', {
    params: {
      base: 'USD',
      symbols: [...MAIN_CURRENCIES, 'NGN'].join(',')
    }
  });

  rateCache.set(cacheKey, response.data);
  return response.data;
}

export async function getExchangeRates(): Promise<ExchangeRate[]> {
  try {
    const data = await fetchLatestRates();

    if (!data.rates || !data.rates['NGN']) {
      console.warn('Invalid rate data received, using mock data');
      return mockRates;
    }

    const { rates } = data;
    const ngnRate = rates['NGN'];

    const transformedRates = MAIN_CURRENCIES.map(code => {
      if (!rates[code]) {
        console.warn(`Rate not found for ${code}, using mock data`);
        return mockRates.find(m => m.fromCurrency === code) || mockRates[0];
      }

      const currentRate = ngnRate / rates[code];
      const rate = transformRateData(code, currentRate);
      
      return exchangeRateSchema.parse(rate);
    });

    return transformedRates;
  } catch (error) {
    console.error('Error fetching exchange rates:', error);
    return mockRates;
  }
}