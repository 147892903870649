import React from 'react';
import {
  Hero,
  Stats,
  LiveRatesSection,
  CTASection,
  PWASection
} from '../components/home';

export function Home() {
  return (
    <div className="min-h-screen">
      <Hero />
      <Stats />
      <LiveRatesSection />
      <PWASection />
      <CTASection />
    </div>
  );
}