import type { ExchangeRate } from './types';

export const mockRates: ExchangeRate[] = [
  {
    fromCurrency: 'USD',
    toCurrency: 'NGN',
    rate: 1534.50,
    change: 0.75,
    timestamp: new Date(),
    symbol: '$'
  },
  {
    fromCurrency: 'EUR',
    toCurrency: 'NGN',
    rate: 1728.18,
    change: -0.32,
    timestamp: new Date(),
    symbol: '€'
  },
  {
    fromCurrency: 'GBP',
    toCurrency: 'NGN',
    rate: 2050.80,
    change: 1.15,
    timestamp: new Date(),
    symbol: '£'
  },
  {
    fromCurrency: 'CAD',
    toCurrency: 'NGN',
    rate: 1134.75,
    change: -0.28,
    timestamp: new Date(),
    symbol: 'C$'
  },
  {
    fromCurrency: 'AUD',
    toCurrency: 'NGN',
    rate: 998.25,
    change: 0.45,
    timestamp: new Date(),
    symbol: 'A$'
  },
  {
    fromCurrency: 'XOF',
    toCurrency: 'NGN',
    rate: 2.48,
    change: 0.15,
    timestamp: new Date(),
    symbol: 'CFA'
  }
];