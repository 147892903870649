import axios from 'axios';
import { formatCurrency, formatPercentage } from '../utils/format/currency';

export interface MarketData {
  symbol: string;
  name: string;
  price: number;
  change: number;
  formattedPrice: string;
  formattedChange: string;
}

// Define the market indicators we want to track
const MARKET_INDICATORS = {
  'NGN/USD': 'USD/NGN Exchange Rate',
  'NGERIA': 'Nigeria Stock Market',
  'NGRPRATE': 'Nigeria Interest Rate',
  'NGRINFR': 'Nigeria Inflation Rate'
};

async function fetchLiveMarketData(): Promise<MarketData[]> {
  const apiKey = import.meta.env.VITE_TRADING_ECONOMICS_KEY;
  
  if (!apiKey) {
    throw new Error('Trading Economics API key is not configured');
  }

  try {
    // Fetch market data from Trading Economics API
    const response = await axios.get('https://api.tradingeconomics.com/markets/symbol/NGN,NGERIA,NGRPRATE,NGRINFR', {
      params: {
        c: apiKey,
        format: 'json'
      }
    });

    return response.data.map((item: any) => {
      const price = Number(item.Last);
      const change = Number(item.DailyChange);
      const symbol = item.Symbol;
      
      return {
        symbol,
        name: MARKET_INDICATORS[symbol as keyof typeof MARKET_INDICATORS] || item.Name,
        price,
        change,
        formattedPrice: symbol === 'NGN/USD' 
          ? formatCurrency(price, 'NGN')
          : formatCurrency(price, ''),
        formattedChange: formatPercentage(change)
      };
    });
  } catch (error) {
    console.error('Error fetching market data:', error);
    throw error;
  }
}

export async function getMarketData(): Promise<MarketData[]> {
  try {
    return await fetchLiveMarketData();
  } catch (error) {
    console.error('Error in getMarketData:', error);
    throw error;
  }
}