import React from 'react';
import { HelpCircle } from 'lucide-react';
import { SectionHeader } from '../ui/SectionHeader';
import { faqs } from '../../data/education/faqs';

export function FrequentlyAskedQuestions() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <SectionHeader icon={HelpCircle} title="Frequently Asked Questions" />

      <div className="space-y-6">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200 last:border-0 pb-6 last:pb-0">
            <h3 className="font-semibold text-lg mb-2">{faq.question}</h3>
            <p className="text-gray-600">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
}