export interface TradingBasic {
  title: string;
  content: string;
}

export const tradingBasics: TradingBasic[] = [
  {
    title: "Understanding Exchange Rates",
    content: "Exchange rates show the value of one currency relative to another. In Nigeria, rates are typically quoted against major currencies like USD, EUR, and GBP.",
  },
  {
    title: "Market Hours",
    content: "The forex market operates 24 hours during weekdays. However, local exchanges and banks have specific operating hours.",
  },
  {
    title: "Types of Transactions",
    content: "Common transactions include spot trades (immediate exchange), forwards (future date exchange), and swaps (combination of spot and forward).",
  },
  {
    title: "Documentation Required",
    content: "Basic requirements include valid ID, proof of address, and transaction purpose documentation. Additional requirements may apply for large transactions.",
  }
];