import React, { useState } from 'react';
import { Currency } from '../../types/converter';
import { formatInputNumber, parseFormattedNumber } from '../../utils/format/currency';

interface CurrencyInputProps {
  amount: number;
  currency: string;
  label: string;
  onAmountChange: (amount: number) => void;
  onCurrencyChange: (currency: string) => void;
  currencies: Currency[];
  readOnly?: boolean;
}

export function CurrencyInput({
  amount,
  currency,
  label,
  onAmountChange,
  onCurrencyChange,
  currencies,
  readOnly = false,
}: CurrencyInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = parseFormattedNumber(value);
    onAmountChange(numericValue);
  };

  const displayValue = readOnly 
    ? formatInputNumber(amount) 
    : amount === 0 
      ? '' 
      : formatInputNumber(amount);

  return (
    <div className="space-y-3">
      <label className="block text-base font-heading font-semibold text-gray-700">{label}</label>
      <div className="flex flex-col sm:flex-row gap-3">
        <div className="relative flex-1">
          <input
            type="text"
            value={displayValue}
            onChange={handleAmountChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className="block w-full rounded-lg border-2 border-gray-300 shadow-sm 
                     focus:border-emerald-500 focus:ring-emerald-500 focus:ring-opacity-50
                     px-4 py-3.5 text-lg bg-white text-gray-900
                     hover:border-gray-400 transition-colors min-w-[120px]"
            placeholder={isFocused ? "0.00" : `0.00 ${currency}`}
            readOnly={readOnly}
            inputMode="decimal"
            style={{ minHeight: '3.5rem' }}
          />
          {!isFocused && amount === 0 && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-600 text-base font-medium">{currency}</span>
            </div>
          )}
        </div>
        <select
          value={currency}
          onChange={(e) => onCurrencyChange(e.target.value)}
          className="block rounded-lg border-2 border-gray-300 shadow-sm 
                   focus:border-emerald-500 focus:ring-emerald-500 focus:ring-opacity-50
                   py-3.5 bg-white text-base text-gray-900
                   hover:border-gray-400 transition-colors w-full sm:w-auto min-w-[160px]"
        >
          {currencies.map((curr) => (
            <option key={curr.code} value={curr.code}>
              {curr.code} - {curr.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}