import React from 'react';

interface PageBannerProps {
  title: string;
  description: string;
  backgroundImage?: string;
}

export function PageBanner({ 
  title, 
  description, 
  backgroundImage = 'https://images.unsplash.com/photo-1642790106117-e829e14a795f'
}: PageBannerProps) {
  return (
    <div className="relative bg-gradient-to-br from-emerald-900 via-emerald-800 to-emerald-900 text-white overflow-hidden">
      <div className="absolute inset-0">
        <div 
          className="absolute inset-0 opacity-10 bg-cover bg-center" 
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
        <div className="absolute inset-0 bg-gradient-to-br from-emerald-900/90 to-emerald-800/90" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-3xl sm:text-4xl font-bold mb-4">{title}</h1>
          <p className="text-lg text-emerald-100 max-w-3xl mx-auto">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}