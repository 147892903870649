import React from 'react';
import { PageBanner } from '../components/shared/PageBanner';
import { Shield, AlertTriangle, BookOpen, HelpCircle } from 'lucide-react';
import { SafetyTips } from '../components/education/SafetyTips';
import { CommonScams } from '../components/education/CommonScams';
import { FrequentlyAskedQuestions } from '../components/education/FAQ';
import { TradingBasics } from '../components/education/TradingBasics';

export function Education() {
  return (
    <div className="min-h-screen bg-gray-50">
      <PageBanner 
        title="Forex Education Center"
        description="Learn how to safely navigate the Nigerian forex market with our comprehensive guides and resources"
      />

      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="grid gap-8">
          <TradingBasics />
          <SafetyTips />
          <CommonScams />
          <FrequentlyAskedQuestions />
        </div>
      </div>
    </div>
  );
}