import { RATE_CONFIG } from './config';

interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

class RateCache {
  private cache = new Map<string, CacheEntry<any>>();
  private readonly ttl = RATE_CONFIG.CACHE_TTL;

  set(key: string, value: any): void {
    this.cache.set(key, {
      data: value,
      timestamp: Date.now() + this.ttl
    });
  }

  get(key: string): any | null {
    const entry = this.cache.get(key);
    if (!entry) return null;

    if (Date.now() > entry.timestamp) {
      this.cache.delete(key);
      return null;
    }

    return entry.data;
  }

  clear(): void {
    this.cache.clear();
  }

  cleanup(): void {
    const now = Date.now();
    for (const [key, entry] of this.cache.entries()) {
      if (now > entry.timestamp) {
        this.cache.delete(key);
      }
    }
  }
}

export const rateCache = new RateCache();

// Run cleanup periodically
setInterval(() => rateCache.cleanup(), RATE_CONFIG.CACHE_TTL);