export interface Scam {
  title: string;
  description: string;
  warning: string;
}

export const commonScams: Scam[] = [
  {
    title: "Too Good To Be True Rates",
    description: "Scammers often offer exchange rates significantly better than market rates to lure victims.",
    warning: "If the rate seems unusually favorable, it's likely a scam.",
  },
  {
    title: "Fake Online Platforms",
    description: "Fraudulent websites that mimic legitimate forex trading platforms.",
    warning: "Always verify platform credentials and licenses.",
  },
  {
    title: "Counterfeit Currency",
    description: "Exchange of fake currency notes in street trading.",
    warning: "Only use authorized exchange services.",
  },
  {
    title: "Investment Schemes",
    description: "Promises of guaranteed high returns through forex investment pools.",
    warning: "Be wary of unsolicited investment opportunities.",
  }
];