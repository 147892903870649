import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { SectionHeader } from '../ui/SectionHeader';
import { commonScams } from '../../data/education/scams';

export function CommonScams() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <SectionHeader 
        icon={AlertTriangle} 
        title="Common Scams to Avoid" 
        iconColor="text-red-600" 
      />

      <div className="grid gap-6">
        {commonScams.map((scam, index) => (
          <div key={index} className="border-l-4 border-red-500 pl-4">
            <h3 className="font-semibold text-lg mb-2">{scam.title}</h3>
            <p className="text-gray-600 mb-2">{scam.description}</p>
            <p className="text-red-600 text-sm font-medium">{scam.warning}</p>
          </div>
        ))}
      </div>
    </div>
  );
}