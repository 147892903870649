import React from 'react';
import { ShieldAlert } from 'lucide-react';

export function AboutDisclaimer() {
  return (
    <section>
      <div className="flex items-center gap-2 mb-6">
        <ShieldAlert className="h-6 w-6 text-red-600" />
        <h2 className="text-2xl font-bold">Important Disclaimer</h2>
      </div>

      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="p-6 border-2 border-red-200 rounded-lg bg-red-50">
          <h3 className="text-xl font-bold text-red-700 mb-4">Please Read Carefully</h3>
          
          <div className="space-y-4 text-gray-700">
            <p>
              The information provided on NigeriaFx is for general information purposes only. 
              Nothing on this website constitutes professional and/or financial advice.
            </p>

            <p>
              While we strive to keep the information up to date and correct, we make no 
              representations or warranties of any kind, express or implied, about the completeness, 
              accuracy, reliability, suitability or availability of the information, products, 
              services, or related graphics contained on the website.
            </p>

            <p>
              Exchange rates displayed are obtained from various sources and are provided for 
              informational purposes only. Actual market rates may vary, and you should confirm 
              current rates with financial institutions before making any transactions.
            </p>

            <p>
              Any reliance you place on the information on this website is strictly at your own risk. 
              You should always verify any information provided here with licensed financial 
              professionals before making any financial decisions.
            </p>

            <p className="font-semibold">
              NigeriaFx shall not be liable for any loss or damage including without limitation, 
              indirect or consequential loss or damage, arising from the use of information 
              contained in this website.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}