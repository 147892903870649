export class RateServiceError extends Error {
  constructor(
    message: string,
    public code: string,
    public status?: number
  ) {
    super(message);
    this.name = 'RateServiceError';
  }
}

export function createError(code: string, message: string, status?: number): RateServiceError {
  return new RateServiceError(message, code, status);
}