import React from 'react';
import { PageBanner } from '../components/shared/PageBanner';
import { HistoricalChart } from '../components/historical/HistoricalChart';
import { MilestoneTimeline } from '../components/historical/MilestoneTimeline';
import { HistoricalSection } from '../components/historical/sections/HistoricalSection';

export function NairaJourney() {
  return (
    <div className="min-h-screen bg-gray-50">
      <PageBanner 
        title="The Naira's Journey"
        description="Exploring 25 years of exchange rate history against major world currencies"
        backgroundImage="https://images.unsplash.com/photo-1640340434855-6084b1f4901c"
      />

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="space-y-12">
          <HistoricalSection />
          <HistoricalChart />
          <MilestoneTimeline />
        </div>
      </div>
    </div>
  );
}