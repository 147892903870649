import React from 'react';

interface LegalPageLayoutProps {
  title: string;
  children: React.ReactNode;
}

export function LegalPageLayout({ title, children }: LegalPageLayoutProps) {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-8">{title}</h1>
      <div className="prose prose-emerald max-w-none">
        {children}
      </div>
    </div>
  );
}