import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

export function Hero() {
  return (
    <div className="relative bg-gradient-to-br from-emerald-900 via-emerald-800 to-emerald-900 text-white">
      <div className="absolute inset-0">
        <div 
          className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1639322537228-f710d846310a')] opacity-10 bg-cover bg-center"
          style={{ backgroundBlendMode: 'multiply' }}
        />
        <div className="absolute inset-0 bg-gradient-to-br from-emerald-900/90 to-emerald-800/90" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 py-32 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto">
          <div className="flex items-center justify-center mb-6">
            <span className="px-4 py-2 rounded-full bg-emerald-700/50 text-emerald-100 text-sm font-medium">
              Real-Time Exchange Rates
            </span>
          </div>
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6 leading-tight font-heading">
            Naira Exchange Rate at Your Fingertips
          </h1>
          <p className="text-xl text-emerald-100 mb-8 leading-relaxed max-w-2xl mx-auto">
            Access real-time exchange rates, AI-powered market analysis, and comprehensive financial insights for informed trading decisions.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <Link
              to="/converter"
              className="bg-emerald-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-emerald-500 transition-colors shadow-lg inline-flex items-center"
            >
              Start Converting <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <Link
              to="/education"
              className="bg-white/10 backdrop-blur-sm text-white px-8 py-4 rounded-lg font-semibold hover:bg-white/20 transition-colors"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}