import { useQuery } from '@tanstack/react-query';
import { getMarketData } from '../services/marketData';

export function useMarketData() {
  return useQuery({
    queryKey: ['marketData'],
    queryFn: getMarketData,
    refetchInterval: 60000, // Refetch every minute
    staleTime: 30000, // Consider data stale after 30 seconds
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    refetchOnWindowFocus: true
  });
}