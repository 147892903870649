import React from 'react';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';
import { LegalSection } from '../../components/legal/LegalSection';

export function Privacy() {
  return (
    <LegalPageLayout title="Privacy Policy">
      <LegalSection title="1. Information We Collect">
        <p className="mb-4">
          We collect information that you provide directly to us, including:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Contact information (email address)</li>
          <li>Usage data and preferences</li>
          <li>Device and browser information</li>
          <li>Transaction history and conversion data</li>
        </ul>
      </LegalSection>

      <LegalSection title="2. How We Use Your Information">
        <p className="mb-4">
          We use the information we collect to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Provide and maintain the Service</li>
          <li>Send you updates and notifications</li>
          <li>Improve our Service</li>
          <li>Comply with legal obligations</li>
          <li>Prevent fraud and abuse</li>
        </ul>
      </LegalSection>

      <LegalSection title="3. Data Security">
        <p className="mb-4">
          We implement appropriate security measures to protect your personal information. 
          However, no method of transmission over the Internet is 100% secure.
        </p>
      </LegalSection>

      <LegalSection title="4. Cookies and Tracking">
        <p className="mb-4">
          We use cookies and similar tracking technologies to track activity on our Service 
          and hold certain information to improve and analyze our Service.
        </p>
      </LegalSection>

      <LegalSection title="5. Third-Party Services">
        <p className="mb-4">
          We may employ third-party companies and individuals to facilitate our Service, 
          provide services on our behalf, or assist us in analyzing how our Service is used.
        </p>
      </LegalSection>

      <LegalSection title="6. Your Rights">
        <p className="mb-4">
          You have the right to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Access your personal data</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Object to data processing</li>
          <li>Data portability</li>
        </ul>
      </LegalSection>

      <LegalSection title="7. Contact Us">
        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us at contact@nigeriafx.com
        </p>
      </LegalSection>
    </LegalPageLayout>
  );
}