export interface NavigationItem {
  to: string;
  label: string;
}

export const MAIN_NAV_ITEMS: NavigationItem[] = [
  { to: '/', label: 'Home' },
  { to: '/about', label: 'About' },
  { to: '/converter', label: 'Converter' },
  { to: '/naira-journey', label: "Naira's Journey" },
  { to: '/education', label: 'Education' },
];

export const LEGAL_NAV_ITEMS: NavigationItem[] = [
  { to: '/terms', label: 'Terms of Service' },
  { to: '/privacy', label: 'Privacy Policy' },
  { to: '/disclaimer', label: 'Disclaimer' },
];