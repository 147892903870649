import React from 'react';
import { RateComparison as MarketComparison } from './market-comparison';

interface RateComparisonProps {
  fromCurrency: string;
  toCurrency: string;
  officialRate: number;
  parallelRate: number;
}

export function RateComparison(props: RateComparisonProps) {
  return <MarketComparison {...props} />;
}