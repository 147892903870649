import React from 'react';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';
import { LegalSection } from '../../components/legal/LegalSection';

export function Terms() {
  return (
    <LegalPageLayout title="Terms and Conditions">
      <LegalSection title="1. Acceptance of Terms">
        <p className="mb-4">
          By accessing and using NigeriaFx ("the Service"), you agree to be bound by these Terms of Service. 
          If you do not agree to these terms, please do not use the Service.
        </p>
      </LegalSection>

      <LegalSection title="2. Use of Service">
        <p className="mb-4">
          The information provided through NigeriaFx is for general informational purposes only. 
          We make no representations or warranties about the accuracy or completeness of the information provided.
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>You must not use the Service for any illegal purposes</li>
          <li>You must not interfere with or disrupt the Service</li>
          <li>You must not collect or store personal data about other users</li>
          <li>You must not attempt to gain unauthorized access to any portion of the Service</li>
          <li>You must not use the Service for commercial purposes without our consent</li>
        </ul>
      </LegalSection>

      <LegalSection title="3. Exchange Rates and Financial Information">
        <p className="mb-4">
          Exchange rates and financial information provided through the Service are obtained from various sources 
          and are provided on an "as is" basis. We do not guarantee the accuracy, timeliness, or completeness 
          of this information.
        </p>
        <p className="mb-4">
          Users should verify all rates and information through official channels before making financial decisions.
        </p>
      </LegalSection>

      <LegalSection title="4. User Accounts">
        <p className="mb-4">
          When you create an account with us, you must provide accurate and complete information. 
          You are solely responsible for the activity that occurs on your account, and you must 
          keep your account password secure.
        </p>
      </LegalSection>

      <LegalSection title="5. Intellectual Property">
        <p className="mb-4">
          The Service and its original content, features, and functionality are owned by NigeriaFx 
          and are protected by international copyright, trademark, patent, trade secret, and other 
          intellectual property laws.
        </p>
      </LegalSection>

      <LegalSection title="6. Modifications">
        <p className="mb-4">
          We reserve the right to modify or replace these terms at any time. Your continued use of 
          the Service following any changes indicates your acceptance of the new terms.
        </p>
        <p className="mb-4">
          It is your responsibility to review these terms periodically for changes. If you do not 
          agree to the modified terms, you should discontinue your use of the Service.
        </p>
      </LegalSection>

      <LegalSection title="7. Governing Law">
        <p className="mb-4">
          These terms shall be governed by and construed in accordance with the laws of the Federal 
          Republic of Nigeria, without regard to its conflict of law provisions.
        </p>
      </LegalSection>

      <LegalSection title="8. Contact Information">
        <p className="mb-4">
          If you have any questions about these Terms, please contact us at contact@nigeriafx.com
        </p>
      </LegalSection>
    </LegalPageLayout>
  );
}