import { useQuery } from '@tanstack/react-query';
import { getExchangeRates } from '../services/rates/api';
import { RATE_CONFIG } from '../services/rates/config';
import type { ExchangeRate } from '../services/rates/types';

export function useExchangeRates() {
  return useQuery<ExchangeRate[], Error>({
    queryKey: ['exchangeRates'],
    queryFn: getExchangeRates,
    refetchInterval: RATE_CONFIG.REFETCH_INTERVAL,
    staleTime: RATE_CONFIG.STALE_TIME,
    cacheTime: RATE_CONFIG.CACHE_TIME,
    retry: RATE_CONFIG.RETRY_ATTEMPTS,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    networkMode: 'always',
    gcTime: 1000 * 60 * 5, // 5 minutes
  });
}