import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { InstallPrompt } from './components/shared/pwa/InstallPrompt';
import { OfflineBanner } from './components/shared/pwa/OfflineBanner';
import { UpdatePrompt } from './components/shared/pwa/UpdatePrompt';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Converter } from './pages/Converter';
import { Education } from './pages/Education';
import { NairaJourney } from './pages/NairaJourney';
import { Terms } from './pages/legal/Terms';
import { Privacy } from './pages/legal/Privacy';
import { Disclaimer } from './pages/legal/Disclaimer';
import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { UserDashboard } from './components/user/UserDashboard';
import { AdminLogin } from './pages/admin/Login';
import { AdminDashboard } from './pages/admin/Dashboard';
import { AuthGuard } from './components/auth/AuthGuard';
import { usePWA } from './hooks/usePWA';

function App() {
  const { needRefresh, updateServiceWorker, closePrompt } = usePWA();

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/converter" element={<Converter />} />
          <Route path="/education" element={<Education />} />
          <Route path="/naira-journey" element={<NairaJourney />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/dashboard"
            element={
              <AuthGuard>
                <UserDashboard />
              </AuthGuard>
            }
          />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/*" element={<AdminDashboard />} />
        </Routes>
      </main>
      <Footer />
      <InstallPrompt />
      <OfflineBanner />
      {needRefresh && (
        <UpdatePrompt
          onUpdate={() => updateServiceWorker?.()}
          onDismiss={closePrompt}
        />
      )}
    </div>
  );
}

export default App;