import React from 'react';
import { Milestone } from 'lucide-react';
import { useExchangeRates } from '../../hooks/useExchangeRates';
import { LoadingSpinner } from '../shared/LoadingSpinner';

const historicalMilestones = [
  {
    year: 1999,
    title: "Introduction of Democracy",
    description: "Return to civilian rule marked relative stability in forex markets",
    rate: "₦21.89 to $1"
  },
  {
    year: 2009,
    title: "Global Financial Crisis Impact",
    description: "Naira faced pressure amid global economic downturn",
    rate: "₦148.90 to $1"
  },
  {
    year: 2016,
    title: "Flexible Exchange Rate Policy",
    description: "CBN introduced more market-determined exchange rate system",
    rate: "₦253.49 to $1"
  },
  {
    year: 2020,
    title: "COVID-19 Pandemic",
    description: "Global pandemic caused market volatility and rate fluctuations",
    rate: "₦380.00 to $1"
  },
  {
    year: 2023,
    title: "Foreign Exchange Reform",
    description: "Unification of exchange rate windows and market liberalization",
    rate: "₦778.80 to $1"
  }
];

export function MilestoneTimeline() {
  const { data: currentRates, isLoading } = useExchangeRates();
  const currentUsdRate = currentRates?.find(r => r.fromCurrency === 'USD')?.rate;

  const milestones = [
    ...historicalMilestones,
    {
      year: 2024,
      title: "Current Era",
      description: "Market-driven exchange rate system with regular rate updates",
      rate: currentUsdRate ? `₦${currentUsdRate.toFixed(2)} to $1` : "Loading..."
    }
  ];

  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h2 className="text-2xl font-bold mb-8">Key Milestones</h2>
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-lg p-8">
      <h2 className="text-2xl font-bold mb-8">Key Milestones</h2>
      
      <div className="relative">
        <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-emerald-200" />
        
        <div className="space-y-8">
          {milestones.map((milestone) => (
            <div key={milestone.year} className="relative pl-12">
              <div className="absolute left-0 p-2 bg-emerald-100 rounded-full">
                <Milestone className="h-4 w-4 text-emerald-600" />
              </div>
              
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-lg font-semibold">{milestone.title}</h3>
                  <span className="text-emerald-600 font-medium">{milestone.year}</span>
                </div>
                <p className="text-gray-600 mb-2">{milestone.description}</p>
                <p className="text-sm font-medium text-emerald-600">{milestone.rate}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}