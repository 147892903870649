import React from 'react';
import { useExchangeRates } from '../../../hooks/useExchangeRates';
import { HistoricalRate } from '../rates/HistoricalRate';
import { LoadingSpinner } from '../../shared/LoadingSpinner';

const BASE_RATES = {
  USD: { rate: 21.89, year: 1999 },
  EUR: { rate: 22.07, year: 1999 },
  GBP: { rate: 35.37, year: 1999 }
};

export function HistoricalSection() {
  const { data: currentRates, isLoading } = useExchangeRates();

  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8">
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  const historicalData = currentRates?.filter(rate => 
    ['USD', 'EUR', 'GBP'].includes(rate.fromCurrency)
  ).map(rate => {
    const baseRate = BASE_RATES[rate.fromCurrency as keyof typeof BASE_RATES];
    const percentageChange = ((rate.rate - baseRate.rate) / baseRate.rate) * 100;

    return {
      pair: `${rate.fromCurrency}/NGN`,
      oldRate: baseRate.rate,
      currentRate: rate.rate,
      percentageChange,
      year: baseRate.year
    };
  });

  return (
    <div className="bg-white rounded-xl shadow-lg p-8">
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2">25-Year Rate Comparison</h2>
        <p className="text-gray-600">
          Historical comparison showing the dramatic change in exchange rates since 1999
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {historicalData?.map((currency) => (
          <HistoricalRate
            key={currency.pair}
            pair={currency.pair}
            oldRate={currency.oldRate}
            currentRate={currency.currentRate}
            percentageChange={currency.percentageChange}
            year={currency.year}
          />
        ))}
      </div>

      <p className="mt-6 text-sm text-gray-500 text-center">
        Data sourced from Central Bank of Nigeria historical archives
      </p>
    </div>
  );
}