import axios from 'axios';
import axiosRetry from 'axios-retry';
import { RATE_CONFIG } from './config';
import { createError } from './errors';

export const exchangeRateApi = axios.create({
  baseURL: RATE_CONFIG.API_BASE_URL,
  timeout: RATE_CONFIG.TIMEOUT,
});

axiosRetry(exchangeRateApi, {
  retries: RATE_CONFIG.RETRY_ATTEMPTS,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      error.response?.status === 429;
  },
});

exchangeRateApi.interceptors.response.use(
  response => response,
  error => {
    const status = error.response?.status;
    const message = error.response?.data?.message || error.message;

    console.error('Exchange Rate API Error:', {
      url: error.config?.url,
      status,
      message
    });

    throw createError(
      'API_ERROR',
      message,
      status
    );
  }
);

export interface ExchangeRateResponse {
  success: boolean;
  base: string;
  rates: Record<string, number>;
  timestamp: number;
}

export interface ConversionResponse {
  success: boolean;
  query: {
    from: string;
    to: string;
    amount: number;
  };
  result: number;
  timestamp: number;
}