import React from 'react';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';
import { LegalSection } from '../../components/legal/LegalSection';

export function Disclaimer() {
  return (
    <LegalPageLayout title="Disclaimer">
      <LegalSection title="Financial Information Disclaimer">
        <p className="mb-4">
          The information provided on NigeriaFx is for general information purposes only and should not 
          be considered as financial advice. We do not guarantee the accuracy, completeness, or timeliness 
          of any information on this website.
        </p>
      </LegalSection>

      <LegalSection title="Exchange Rates">
        <p className="mb-4">
          Exchange rates displayed on NigeriaFx are obtained from various sources and are provided for 
          informational purposes only. Actual market rates may vary, and you should confirm current rates 
          with financial institutions before making any transactions.
        </p>
      </LegalSection>

      <LegalSection title="No Investment Advice">
        <p className="mb-4">
          Nothing on this website constitutes professional and/or financial advice. You should consult 
          with a financial advisor before making any investment decisions.
        </p>
      </LegalSection>

      <LegalSection title="Third-Party Content">
        <p className="mb-4">
          NigeriaFx may include content from third-party sources. We are not responsible for the accuracy, 
          reliability, or availability of such content. Any reliance you place on third-party information 
          is strictly at your own risk.
        </p>
      </LegalSection>

      <LegalSection title="Service Availability">
        <p className="mb-4">
          We do not guarantee uninterrupted access to our service. The website may be unavailable from 
          time to time for maintenance or other reasons. We reserve the right to modify or discontinue 
          any feature or service at any time.
        </p>
      </LegalSection>
    </LegalPageLayout>
  );
}