import React from 'react';
import { LogOut, Settings } from 'lucide-react';
import { Button } from '../ui/Button';
import { useAuth } from '../../hooks/useAuth';

interface AuthHeaderProps {
  onLogout: () => void;
}

export function AuthHeader({ onLogout }: AuthHeaderProps) {
  const { user } = useAuth();

  return (
    <div className="bg-emerald-700 text-white">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold">Welcome back!</h1>
            <p className="text-emerald-100">{user?.email}</p>
          </div>
          <div className="flex items-center gap-4">
            <Button
              variant="outline"
              className="text-white border-white hover:bg-emerald-600"
              leftIcon={<Settings className="h-4 w-4" />}
            >
              Settings
            </Button>
            <Button
              variant="outline"
              className="text-white border-white hover:bg-emerald-600"
              leftIcon={<LogOut className="h-4 w-4" />}
              onClick={onLogout}
            >
              Sign out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}