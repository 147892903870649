import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, TrendingUp } from 'lucide-react';
import { LiveRates } from '../converter/LiveRates';

export function LiveRatesSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-3xl font-bold mb-2 font-heading">Live Exchange Rates</h2>
            <p className="text-gray-600">Stay updated with real-time currency exchange rates</p>
          </div>
          <Link
            to="/converter"
            className="text-emerald-600 hover:text-emerald-700 flex items-center font-medium"
          >
            View All Rates <ArrowRight className="ml-1 h-5 w-5" />
          </Link>
        </div>
        <LiveRates />
      </div>
    </section>
  );
}