import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AdminLayout } from '../../components/admin/Layout';
import { Stats } from '../../components/admin/Stats';
import { RateManagement } from '../../components/admin/RateManagement';

export function AdminDashboard() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const isAuthenticated = localStorage.getItem('adminAuth') === 'true';
    if (!isAuthenticated) {
      navigate('/admin/login');
    }
  }, [navigate]);

  return (
    <AdminLayout>
      <Routes>
        <Route path="/" element={
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="py-4">
                <Stats />
                <div className="mt-8">
                  <RateManagement />
                </div>
              </div>
            </div>
          </div>
        } />
      </Routes>
    </AdminLayout>
  );
}