import React from 'react';
import { Info } from 'lucide-react';

export function AboutPurpose() {
  return (
    <section>
      <div className="flex items-center gap-2 mb-6">
        <Info className="h-6 w-6 text-emerald-600" />
        <h2 className="text-2xl font-bold">About Our Service</h2>
      </div>
      
      <div className="bg-white rounded-lg shadow-md p-8">
        <p className="text-lg text-gray-600 leading-relaxed mb-6">
          NigeriaFx is an informational platform that provides real-time currency exchange rates 
          and market data for our website visitors. Our service is designed to offer transparency 
          by displaying both official and parallel market rates for reference purposes only.
        </p>
        
        <div className="grid md:grid-cols-2 gap-6 mt-8">
          <div className="p-6 bg-emerald-50 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">What We Are</h3>
            <ul className="space-y-2 text-gray-600">
              <li>• An information-only platform</li>
              <li>• A source for exchange rate data</li>
              <li>• An educational resource</li>
              <li>• A market rates comparison tool</li>
            </ul>
          </div>
          
          <div className="p-6 bg-red-50 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">What We Are Not</h3>
            <ul className="space-y-2 text-gray-600">
              <li>• Not a financial advisor</li>
              <li>• Not a trading platform</li>
              <li>• Not a currency exchange service</li>
              <li>• Not a financial consultant</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}