import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Info } from 'lucide-react';
import { formatCurrency } from '../../utils/format/currency';
import { useExchangeRates } from '../../hooks/useExchangeRates';
import { LoadingSpinner } from '../shared/LoadingSpinner';

const historicalData = [
  { year: 1999, USD: 21.89, EUR: 22.07, GBP: 35.37 },
  { year: 2001, USD: 111.94, EUR: 99.47, GBP: 161.78 },
  { year: 2004, USD: 132.85, EUR: 165.23, GBP: 243.12 },
  { year: 2007, USD: 125.83, EUR: 172.64, GBP: 251.35 },
  { year: 2009, USD: 148.90, EUR: 208.89, GBP: 232.76 },
  { year: 2011, USD: 154.80, EUR: 215.55, GBP: 250.33 },
  { year: 2014, USD: 167.50, EUR: 204.68, GBP: 276.45 },
  { year: 2016, USD: 253.49, EUR: 282.45, GBP: 337.78 },
  { year: 2019, USD: 306.95, EUR: 343.27, GBP: 401.32 },
  { year: 2021, USD: 410.25, EUR: 495.50, GBP: 565.75 },
  { year: 2023, USD: 778.80, EUR: 860.25, GBP: 998.50 }
];

const currencyInfo = {
  USD: {
    name: 'US Dollar',
    color: '#DC2626',
    symbol: '$'
  },
  EUR: {
    name: 'Euro',
    color: '#2563EB',
    symbol: '€'
  },
  GBP: {
    name: 'British Pound',
    color: '#7C3AED',
    symbol: '£'
  }
};

export function HistoricalChart() {
  const [hoveredCurrency, setHoveredCurrency] = useState<string | null>(null);
  const { data: currentRates, isLoading } = useExchangeRates();

  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8">
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  const chartData = [
    ...historicalData,
    {
      year: 2024,
      USD: currentRates?.find(r => r.fromCurrency === 'USD')?.rate || 0,
      EUR: currentRates?.find(r => r.fromCurrency === 'EUR')?.rate || 0,
      GBP: currentRates?.find(r => r.fromCurrency === 'GBP')?.rate || 0
    }
  ];

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
          <p className="font-semibold mb-2">{label}</p>
          <div className="space-y-1">
            {payload.map((entry: any) => (
              <div
                key={entry.name}
                className="flex items-center justify-between gap-4"
                style={{ color: currencyInfo[entry.name as keyof typeof currencyInfo].color }}
              >
                <span className="font-medium">{entry.name}/NGN:</span>
                <span>₦{entry.value.toLocaleString()}</span>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-8">
      <div className="mb-8">
        <div className="flex items-start justify-between">
          <div>
            <h2 className="text-2xl font-bold mb-2">25 Years of Exchange Rates</h2>
            <p className="text-gray-600">Historical exchange rates against major currencies (1999-2024)</p>
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-500 bg-gray-50 p-2 rounded-lg">
            <Info className="h-4 w-4" />
            <span>Hover over the lines for detailed rates</span>
          </div>
        </div>
      </div>
      
      <div className="h-[500px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            onMouseMove={(e) => {
              if (e.activePayload) {
                setHoveredCurrency(e.activePayload[0].name);
              }
            }}
            onMouseLeave={() => setHoveredCurrency(null)}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke="#E5E7EB"
              vertical={false}
            />
            <XAxis
              dataKey="year"
              stroke="#6B7280"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              padding={{ left: 10, right: 10 }}
            />
            <YAxis
              stroke="#6B7280"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `₦${value}`}
              domain={['dataMin - 100', 'dataMax + 100']}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              verticalAlign="top"
              height={36}
            />
            {Object.entries(currencyInfo).map(([currency, info]) => (
              <React.Fragment key={currency}>
                <Line
                  type="monotone"
                  dataKey={currency}
                  stroke={info.color}
                  strokeWidth={hoveredCurrency === currency ? 3 : 2}
                  dot={false}
                  activeDot={{ r: 6, strokeWidth: 2 }}
                  name={currency}
                />
                <ReferenceLine
                  y={chartData[chartData.length - 1][currency as keyof typeof chartData[0]]}
                  stroke={info.color}
                  strokeDasharray="3 3"
                  label={{
                    value: `Current: ₦${chartData[chartData.length - 1][currency as keyof typeof chartData[0]].toLocaleString()}`,
                    position: 'right',
                    fill: info.color,
                    fontSize: 12,
                  }}
                />
              </React.Fragment>
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}