import React from 'react';
import { AlertCircle } from 'lucide-react';

export function AboutLimitations() {
  return (
    <section>
      <div className="flex items-center gap-2 mb-6">
        <AlertCircle className="h-6 w-6 text-amber-600" />
        <h2 className="text-2xl font-bold">Service Limitations</h2>
      </div>

      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="space-y-6">
          <div className="p-4 bg-amber-50 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">Information Purpose Only</h3>
            <p className="text-gray-600">
              All data and content provided on NigeriaFx is for informational purposes only. 
              We do not provide financial advice, trading services, or investment recommendations.
            </p>
          </div>

          <div className="p-4 bg-amber-50 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">No Trading Services</h3>
            <p className="text-gray-600">
              NigeriaFx does not facilitate currency exchanges or trades. We do not offer any 
              platform for executing financial transactions.
            </p>
          </div>

          <div className="p-4 bg-amber-50 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">No Financial Advice</h3>
            <p className="text-gray-600">
              The information provided should not be construed as financial advice. Users should 
              consult with qualified financial advisors for personalized recommendations.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}