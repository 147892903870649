import React from 'react';
import { BookOpen } from 'lucide-react';
import { SectionHeader } from '../ui/SectionHeader';
import { tradingBasics } from '../../data/education/basics';

export function TradingBasics() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <SectionHeader icon={BookOpen} title="Trading Basics" />

      <div className="grid gap-6">
        {tradingBasics.map((item, index) => (
          <div key={index} className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">{item.title}</h3>
            <p className="text-gray-600">{item.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}