import React from 'react';
import { ArrowDownUp } from 'lucide-react';
import { CurrencyInput } from './CurrencyInput';
import { Button } from '../ui/Button';
import { AVAILABLE_CURRENCIES } from '../../types/converter';
import { formatRate } from '../../utils/format/currency';

interface ConversionFormProps {
  fromAmount: number;
  fromCurrency: string;
  toCurrency: string;
  toAmount: number;
  rate: number;
  onFromAmountChange: (amount: number) => void;
  onFromCurrencyChange: (currency: string) => void;
  onToCurrencyChange: (currency: string) => void;
  onSwapCurrencies: () => void;
  onConvert: () => void;
}

export function ConversionForm({
  fromAmount,
  fromCurrency,
  toCurrency,
  toAmount,
  rate,
  onFromAmountChange,
  onFromCurrencyChange,
  onToCurrencyChange,
  onSwapCurrencies,
  onConvert,
}: ConversionFormProps) {
  // Round toAmount to 2 decimal places
  const roundedToAmount = Math.round(toAmount * 100) / 100;

  return (
    <div className="bg-white rounded-xl shadow-lg p-8 border-2 border-gray-100">
      <h2 className="text-2xl font-heading font-bold text-gray-800 mb-8 flex items-center">
        <span className="bg-emerald-100 rounded-full p-2 mr-3">
          <span className="text-emerald-600 text-xl">₦</span>
        </span>
        Currency Converter
      </h2>
      
      <div className="space-y-8">
        <CurrencyInput
          amount={fromAmount}
          currency={fromCurrency}
          label="From"
          onAmountChange={onFromAmountChange}
          onCurrencyChange={onFromCurrencyChange}
          currencies={AVAILABLE_CURRENCIES}
        />

        <div className="flex justify-center">
          <Button
            variant="outline"
            size="sm"
            onClick={onSwapCurrencies}
            className="rounded-full p-3 hover:bg-emerald-50 border-2 border-emerald-200 hover:border-emerald-300"
          >
            <ArrowDownUp className="h-5 w-5 text-emerald-600" />
          </Button>
        </div>

        <CurrencyInput
          amount={roundedToAmount}
          currency={toCurrency}
          label="To"
          onAmountChange={() => {}}
          onCurrencyChange={onToCurrencyChange}
          currencies={AVAILABLE_CURRENCIES}
          readOnly
        />

        <Button
          variant="primary"
          className="w-full bg-gradient-to-r from-emerald-600 to-emerald-500 
                   hover:from-emerald-700 hover:to-emerald-600 
                   text-lg py-4 shadow-md font-heading font-semibold text-white"
          onClick={onConvert}
        >
          Convert Currency
        </Button>

        <div className="text-center bg-emerald-50 rounded-lg p-5 border border-emerald-100">
          <p className="text-sm text-emerald-700 font-medium mb-1">Current Exchange Rate</p>
          <p className="text-xl font-heading font-semibold text-emerald-800">
            1 {fromCurrency} = {formatRate(rate)} {toCurrency}
          </p>
        </div>
      </div>
    </div>
  );
}