import { z } from 'zod';

// Zod schema for runtime validation
export const exchangeRateSchema = z.object({
  fromCurrency: z.string().length(3),
  toCurrency: z.string().length(3),
  rate: z.number().positive(),
  change: z.number(),
  timestamp: z.date(),
  symbol: z.string()
});

export type ExchangeRate = z.infer<typeof exchangeRateSchema>;

export const currencySchema = z.object({
  code: z.string().length(3),
  name: z.string(),
  symbol: z.string()
});

export type Currency = z.infer<typeof currencySchema>;

export const conversionResultSchema = z.object({
  fromAmount: z.number().positive(),
  toAmount: z.number().positive(),
  rate: z.number().positive(),
  fromCurrency: z.string().length(3),
  toCurrency: z.string().length(3),
  timestamp: z.date()
});

export type ConversionResult = z.infer<typeof conversionResultSchema>;

export const conversionHistorySchema = z.object({
  id: z.string(),
  result: conversionResultSchema
});

export type ConversionHistory = z.infer<typeof conversionHistorySchema>;